@import '../variables.sass'
.articles-container
    width: 100%
.articles-menu
    width: calc(100vw - 265px)
    position: absolute
    top: 0
    background: $esnBlue
    display: flex
    height: 75px
    z-index: 10
    border-bottom: 1px solid #19a6da
    // border-bottom: 1px solid #3b3eaf
    color: white
    top: 65px
    .title
        width: calc(100% - 75px)
        display: flex
        align-items: center
        justify-content: center
        font-family: KelsonBold
        font-size: 150%
    .hamburger
        font: inherit
        display: flex
        align-items: center
        overflow: visible
        margin: 0
        padding: 15px
        cursor: pointer
        transition-timing-function: linear
        transition-duration: .15s
        transition-property: opacity,filter
        text-transform: none
        color: inherit
        border: 0
        background-color: transparent
        .box
            position: relative
            display: inline-block
            width: 40px
            height: 24px
            .hamburger-inner
                position: absolute
                width: 40px
                height: 4px
                transition-timing-function: ease
                transition-duration: .15s
                transition-property: transform
                border-radius: 4px
                background-color: white
                &::before
                    position: absolute
                    width: 40px
                    height: 4px
                    transition-timing-function: ease
                    transition-duration: .15s
                    transition-property: transform
                    border-radius: 4px
                    background-color: white
                    content: ''
                    top: 10px
                    transition-timing-function: ease
                    transition-duration: .15s
                    transition-property: transform,opacity
                &::after
                    position: absolute
                    width: 40px
                    height: 4px
                    transition-timing-function: ease
                    transition-duration: .15s
                    transition-property: transform
                    border-radius: 4px
                    background-color: white
                    content: ''
                    top: 20px
    &.active
        .hamburger
            .hamburger-inner
                transform: translate3d(0,10px,0) rotate(45deg)
                &:before
                    transform: rotate(-45deg) translate3d(-5.71429px,-6px,0)
                    opacity: 0
                &:after
                    transform: translate3d(0,-20px,0) rotate(-90deg)

.articles-list
    position: absolute
    top: 75px
    background: $esnBlue
    margin-top: 0
    width: calc(100vw - 265px)
    border: 0
    top: -75px
    transition: top 0.5s ease, opacity 0.5s ease
    z-index: 9
    color: white
    &.active
        //site header + articles menu
        top: calc(75px + 65px)
    .list-item
        cursor: pointer 
        z-index: 1
        display: flex
        align-items: center
        justify-content: center
        padding: 0.5rem 0 0.5rem 0
        font-family: KelsonBold
        padding-right: 75px
        text-decoration: none
        color: white
        // border: 1px solid black
        &:hover
            background: lighten($esnBlue, 5%)
.articles
    top: 75px
    min-height: 300px
    padding-top: 0 !important
    .image-wrapper
        padding: 0
        img
            min-height: 500px
            // max-height: 500px
            object-fit: cover
            width: 100%
    .content
        padding: 30px
        // padding: 15px 15px 30px 15px
        background: #f9f9f9
        min-height: 250px
        .description
            padding-top: 10px
            text-align: justify
            .photos-carousel
                width: calc(100% + 60px)
                margin-left: -30px
                margin-right: -30px
        .bold
            font-weight: 600
        .tjustify
            text-align: justify
                    
@media ( max-width: 1317px  )
    .articles-menu, .articles-list
        width: 100%
    .articles.page-container
        padding-left: 0
        padding-right: 0

@media ( max-width: 767px  )
    .articles .content .description .photos-carousel
        width: calc(100% + 30px)
        margin-left: -15px
        margin-right: -15px
    .articles-menu
        top: 80px
    .articles-menu, .articles-list
        width: calc(100%)
    .articles-list
        opacity: 0
        &.active
            top: calc(75px + 80px)
            opacity: 1
    .page-container.articles
        padding-left: 0
        padding-right: 0
        .content
            padding-left: 15px
            padding-right: 15px
            text-align: justify
    .articles
        .image-wrapper
            img
                // max-height: 300px
                // min-height: 250px
                max-height: unset
                min-height: unset

@media ( max-width: 480px  )
    .articles-menu, .articles-list
        .list-item
            padding-right: 0