@import '../variables.sass'

/* Single Event Page */

.single-event-wrapper
    padding: 0
    padding-right: 250px
    max-width: 850px
    min-height: calc(100vh - 180px - 65px - 1rem)
    .image-wrapper
        padding:0
        text-align: center
        position: relative
        border-left: 1px solid #d8d8d8
        border-right: 1px solid #d8d8d8
        img
            width: 100%
        .user-counter
            position: absolute
            bottom: 0
            left: 0
            height: 0
            width: 0
            border-bottom: 90px solid $niceRed
            border-right: 90px solid transparent
            font-size: 1rem
            color: black
            .inside
                color: white
                display: flex
                flex-direction: column
                align-items: center
                width: 50px
                height: 50px
                bottom: -39px
                left: 0
                position: relative
                font-family: KelsonBold
                img
                    width: 25px
                    height: 25px
    .description-wrapper
        border-left: 1px solid #d8d8d8
        border-right: 1px solid #d8d8d8
        margin: auto
        padding: 0
        position: relative
        background: white
        padding: 1rem
        .name-container
            display: flex
            align-items: center
            .configure-container
                position: relative
                height: 2rem
                width: 10%
                text-align: right
                .configure-icon
                    height: 2rem
                    width: 2rem
                    cursor: pointer
                .submenu
                    position: absolute
                    top: 2.5rem
                    right: 0
                    display: none
                    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2)
                    &.active
                        display: block
                    .link
                        text-align: center
                        display: block
                        width: 100px
                        color: black
                        background: #f9f9f9
                        cursor: pointer
                        padding: 0.35rem 1.5rem
                        &:hover
                            text-decoration: none
                            background: #f1f1f1
                            font-weight: bold

                        
            .name
                text-align: left
                width: 90%
        .icon
            top: 0
            width: 1.5rem
            height: 1.5rem
        >div
            margin-top: 1rem
        .name-container
            margin-top: 0
        .date, .location, .price
            display: flex
            align-items: center
        .separator
            height: 1px
            width: 85%
            background: $niceRed
            // margin-left: auto 
            margin-right: auto
            margin-bottom: 1rem
            margin-top: 0
    .event-description
        background: white
        padding: 1rem
        word-break: break-word
        font-size: 90%
        min-height: 6rem
        border-left: 1px solid #d8d8d8
        border-right: 1px solid #d8d8d8
    .disclaimer-login
        border-left: 1px solid #d8d8d8
        border-right: 1px solid #d8d8d8
        color: #90949c
        font-size: 1rem
        line-height: 1rem
        text-align: center
        padding: 1rem
    .actions-container
        padding: 1rem
        border-left: 1px solid #d8d8d8
        border-right: 1px solid #d8d8d8
        .rate-container
            margin-top: 1rem
            display: flex
            justify-content: space-between
            .left
                display: flex
                align-items: center
            .average
                display: flex
                align-items: center
                font-family: KelsonBold
                font-size: 1rem
                color: #90949c
                .votes
                    margin-right: 1rem
            p
                margin-bottom: 0
                font-family: KelsonBold
                display: inline
                margin-right: 0.5rem
                font-size: 1.4rem
                color: black
            .stars-container
                display: flex
                width: 200px
                &:hover
                    img
                        filter: grayscale(0)
                img
                    width: 40px
                    height: 40px
                    filter: grayscale(100)
                    cursor: pointer
                    padding: 0.15rem
                    &.active
                        filter: grayscale(0)
                    &:hover 
                        & ~ img
                            filter: grayscale(100)
        .interested-container
            display: flex
            align-items: center
            margin-bottom: 1rem
            div:nth-child(2)
                line-height: 0.8rem
        .btn
            background: #f6f6f6
            min-width: 120px
            border-color: #ccd0d5
            color: black
            padding: .175rem .35rem
            font-size: 0.8rem
            border: 1px solid rgba(0,0,0,.15)
        .dropdown-menu
            font-size: 0.8rem
            min-width: 140px
            padding: 0
            .dropdown-item
                padding-top: 0.5rem
                padding-bottom: 0.5rem
                background: white
        .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle
            background: #f6f6f6
            color: black
            border: none
        .dropdown-item:active
            background: #dadada
            color: black
        .btn-primary.focus, .btn-primary:focus
            box-shadow: 0 0 0 0.2rem #c414144f
        .btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show>.btn-primary.dropdown-toggle:focus
            box-shadow: 0 0 0 0.2rem #c414144f
        .feedback-container
            display: flex
            align-items: center
            .btn
                margin-right: 1rem
        .feedback
            background: $niceRed
            color: white
            border: 0
        .feedback:not(:disabled):not(.disabled).active, .feedback:not(:disabled):not(.disabled):active, .show>.feedback.dropdown-toggle, .feedback:hover
            background: #e08282
            color: white
        .disclaimer
            color: $disclaimer
            font-size: 0.7rem
            line-height: 0.8rem
        .interest-choice
            position: relative
            .dropdown
                position: absolute
@media (max-width: 1317px)
    .single-event-wrapper
        padding-right: 0
        max-width: 600px
@media screen and ( max-width: 767px)
    .single-event-wrapper
        min-height: initial
        .actions-container
            .interested-container, .feedback-container
                flex-direction: column
                align-items: flex-start
                .btn
                    margin-bottom: 0.5rem
            .btn
                width: 110px
                font-size: 0.8rem
        .image-wrapper
            padding:0
        .description-wrapper
            margin: auto
            position: relative
            // padding: 0 2rem
            .name-container
                align-items: flex-start
                .configure-container
                    margin-top: 1rem
                .name
                    text-align: left
                    margin: 1rem auto 1rem 0
                    width: 85%
            .icon
                width: 1.3rem
                height: 1.3rem
            .date, .location, .price
                font-size: 1rem
            >div
                margin-top: 1rem
@media screen and (max-width: 480px)
    .single-event-wrapper .actions-container .rate-container .average
        font-size: 0.8rem
@media screen and (max-width: 440px)
    .single-event-wrapper .actions-container .rate-container
        flex-direction: column
    .single-event-wrapper .actions-container .rate-container .left
        margin-bottom: 0.5rem
        