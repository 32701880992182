@import '../variables.sass'

@media (min-width:1025px), (min-width: 992px) and (orientation: landscape)
    .team-container
        .board-members
            .card
                &:hover, &.clicked
                    .front
                        transform: rotateY(180deg)
                    .back
                        transform: rotateY(0deg)
.team-container
    overflow: hidden
    .board-members
        margin-left: auto
        margin-right: auto
        width: 100%
        display: flex
        flex-wrap: wrap
        justify-content: center
        .card
            width: 300px
            min-height: 450px
            height: 450px
            margin-left: 1%
            margin-right: 1%
            margin-bottom: 2%
            border-radius: 15px
            overflow: hidden
            text-align: center
            border: none
            cursor: pointer
            background: transparent
            .background
                transition: filter 0.6s
                filter: blur(3px)
            &.clicked
                .front
                    transform: rotateY(180deg)
                .back
                    transform: rotateY(0deg)
            .front
                transition: transform 0.6s
                transform-style: preserve-3d
                -webkit-backface-visibility: hidden
                backface-visibility: hidden
                background: #ffffffdb
                min-height: 450px
                &:after
                    content: ""
                    position: absolute
                    bottom: 0
                    right: 0
                    border-style: solid
                    background: $esnBlue
                    -webkit-box-shadow: -1px -1px 1px rgba(0,0,0,0.3), -2px -2px 1px rgba(0,0,0,0.2)
                    -moz-box-shadow: -1px -1px 1px rgba(0,0,0,0.3), -2px -2px 1px rgba(0,0,0,0.2)
                    box-shadow: -1px -1px 1px rgba(0,0,0,0.3), -2px -2px 1px rgba(0,0,0,0.2)
                    /* Firefox 3.0 damage limitation */
                    display: block 
                    width: 0
                    border-width: 12px
                    border-color: transparent #e9ebee #e9ebee transparent
                    -moz-border-radius: 10px 0 0 0
                    border-radius: 10px 0 0 0
                    z-index: 10
                .info-front
                    padding:1rem 0 1.5rem 0
                    position: absolute
                    bottom: 0
                    background: #ffffffdb
                    width: 100%
                    z-index: 7
                    height: 150px
                    .name
                        font-family: KelsonBold
                        font-size: 130%
                    .position
                        font-family: KelsonNormal
                    .separator
                        width: 30%
                        height: 4px
                        background: $esnBlue
                        margin: 0.5rem auto
                        border-radius: 20px
                    .name,.position
                        padding-left: 1rem
                        padding-right: 1rem
                        margin-top: 0.25rem
                    .mail
                        margin-top: 0.25rem
                        a
                            text-decoration: none
                            color: $esnBlue

            .back
                position: absolute
                width: 100%
                height: 100%
                transition: transform 0.6s
                transform-style: preserve-3d
                -webkit-backface-visibility: hidden
                backface-visibility: hidden
                transform: rotateY(180deg)
                text-align: left
                overflow: hidden
                min-height: 450px
                &:after
                    content: ""
                    position: absolute
                    bottom: 0
                    left: 0
                    border-style: solid
                    background: $esnBlue
                    -webkit-box-shadow: 1px -1px 1px rgba(0,0,0,0.3), 2px -2px 1px rgba(0,0,0,0.2)
                    -moz-box-shadow: 1px -1px 1px rgba(0,0,0,0.3), 2px -2px 1px rgba(0,0,0,0.2)
                    box-shadow: 1px -1px 1px rgba(0,0,0,0.3), 2px -2px 1px rgba(0,0,0,0.2)
                    /* Firefox 3.0 damage limitation */
                    display: block 
                    width: 0
                    border-width: 12px
                    border-color: transparent transparent #e9ebee #e9ebee 
                    -moz-border-radius: 0px 10px 0 0
                    border-radius: 0px 10px 0 0
                    z-index: 10
                img
                    filter: blur(3px)
                .text
                    padding: 1rem
                    text-align: justify
                    position: absolute
                    top: 0
                    left: 0
                    background: #ffffffdb
                    width: 100%
                    height: 100%
                    font-size: 90%
                    .bold
                        font-weight: 600
        &.audit
            .card
                .front
                    &:after
                        background: $niceRed
                    .info-front
                        .separator
                            background: $niceRed
                        .mail a
                            color: $niceRed
                .back
                    &:after
                        background: $niceRed
        &.coordinators
            .card
                .front
                    &:after
                        background: $esnOrange
                    .info-front
                        .separator
                            background: $esnOrange
                        .mail a
                            color: $esnOrange
                .back
                    &:after
                        background: $esnOrange
    .heading
        display: flex
        justify-content: space-between
        margin-bottom: 2rem
        .category
            cursor: pointer
        h3
            margin-bottom: 1rem
            text-align: center
            font-family: KelsonBold
        .astrodivider 
            margin-left: auto
            margin-right: auto
            width: 250px
            max-width: 100%
            position: relative
            margin-bottom: 2rem
            margin-top: 1rem
            span
                width: 50px 
                height: 50px 
                position: absolute 
                bottom: 100%
                margin-bottom: -25px
                left: 50% 
                margin-left:-25px
                border-radius: 100%
                box-shadow: 0 2px 4px $teamGray
                transition: box-shadow 0.3s
                background: #fff
            i
                position: absolute
                top: 4px 
                bottom: 4px
                left: 4px
                right: 4px
                border-radius:  100%
                // border: 1px dashed $teamGray
                transition: border 0.3s
                text-align: center
                display: flex
                align-items: center
                justify-content: center
                img
                    width: 30px
        .astrodividermask 
            overflow: hidden 
            height: 20px 
            &:after
                content: '' 
                display: block 
                margin: -25px auto 0
                width: 100% 
                height: 25px  
                border-radius: 125px / 12px
                box-shadow: 0 0 8px $teamGray
                transition: box-shadow 0.3s
        .category
            &.active
                .astrodivider
                    span
                        box-shadow: 0 2px 4px $esnBlue
                    i
                        // border: 1px dashed $esnBlue
                .astrodividermask 
                    &:after
                        box-shadow: 0 0 8px $esnBlue
            &.coordinators.active
                .astrodivider
                    span
                        box-shadow: 0 2px 4px $esnOrange
                    i
                        // border: 1px dashed $esnOrange
                .astrodividermask 
                    &:after
                        box-shadow: 0 0 8px $esnOrange
            &.audit.active
                .astrodivider
                    span
                        box-shadow: 0 2px 4px $niceRed
                    i
                        // border: 1px dashed $niceRed
                .astrodividermask 
                    &:after
                        box-shadow: 0 0 8px $niceRed

    .carousel-indicators
        display: none
    .carousel-control-prev
        // left: -12%
    .carousel-control-next
        // right: -12%
    .carousel-control-next-icon
        background-image: url('../../images/right-arrow.svg')
        width: 40px
        height: 40px
    .carousel-control-prev-icon
        background-image: url('../../images/left-arrow.svg')
        width: 40px
        height: 40px
    .carousel
        min-height: 940px
    .carousel-item
        .category-name
            display: none
    .carousel-item.active
        min-height: 940px
        // overflow: auto
        // display: flex
        justify-content: center
@media (min-width: 1400px)
    .team-container
        .carousel-control-next, .carousel-control-prev
            width: 10%
        .carousel-control-next
            right: -75px
        .carousel-control-prev
            left: -75px
@media (min-width: 1318px)
    .team-container .board-members
        padding-left: 0
        padding-right: 0
    .team-container
        overflow: initial
        // .carousel-control-next, .carousel-control-prev
        //     width: 10%
        // .carousel-control-next
        //     right: -75px
        // .carousel-control-prev
        //     left: -75px
@media (max-width: 1400px) and (min-width: 1318px)
    .team-container
        .carousel-control-next, .carousel-control-prev
            width: 6%
        .carousel-control-next
            right: -3.5%
        .carousel-control-prev
            left: -3.5%
@media (max-width: 1024px) and (orientation: portrait)
    .team-container
        .carousel-inner
            overflow: initial
        .carousel-item.active
            overflow: initial
            height: unset
        overflow: hidden
        .carousel-control-next
            right: -15px
        .carousel-control-prev
            left: -15px
        .carousel-control-next, .carousel-control-prev
            width: 4%
    // .team-container
    //     .board-members
    //         .card
    //             &:hover
    //                 .front
    //                     transform: initial
    //                 .back
    //                     transform: initial
@media (max-width: 1024px) and (orientation: landscape)
    .team-container .carousel-item.active
        height: auto
@media (max-width: 1024px)
    .team-container .carousel
        height: auto
@media (max-width: 767px)
    .team-container
        .carousel
            height: auto
        .heading
            display: none
        .carousel-item
            .category-name
                display: block
                text-align: center
                font-family: KelsonBold
                margin-bottom: 1.5rem
        .carousel-control-next
            right: 0
            height: 250px
            position: fixed
            top: 28%
            width: 30px
        .carousel-control-prev
            left: 0
            width: 30px
            height: 250px
            position: fixed
            top: 28%
        .carousel-control-next-icon, .carousel-control-prev-icon
            width: 25px
            height: 25px
        .board-members
            .card
                margin-bottom: 4%
        // .heading
        //     display: flex
        //     .category
        //         h3
        //             font-size: 0.8rem
        //         .astrodivider
        //             width: 100px
        //             span
        //                 width: 40px
        //                 height: 40px
        //                 margin-bottom: -20px
        //                 margin-left: -20px
        //             i
        //                 img
        //                     width: 25px
@media (max-width: 350px)
    .team-container .board-members .card
        max-width: 250px
        width: 250px
    .team-container .board-members .card .back .text
        font-size: 75%
    .team-container .board-members .card
        height: 375px
        min-height: 375px
        .front, .back
            min-height: 375px
