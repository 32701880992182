@import '../variables.sass'

.footer-wrapper
    background: #2f2f2f !important
    // margin-top: 5rem
    padding: 1rem 0 0
    padding-right: 250px
    min-height: 180px
    .footer
        display: flex
        flex-direction: row
        color: white
        padding-bottom: 1rem
        >div
            width: 33%
            padding-left: 1rem
            padding-right: 1rem
            .title
                font-size: 1.25rem
                font-family: KelsonBold
            .desc
                font-size: 0.75rem
                color: #888
            &.esn-full-logo
                display: flex
                flex-direction: column
                justify-content: space-around
                align-items: center
                a
                    font-size: 0.8rem
                    color: $esnBlue
                img
                    width: 70%
                    max-width: 200px
    .footer-low
        background: #292929
        color: #888
        font-size: 0.65rem
        padding: 0.7rem
        text-align: center
        a
            color: $esnBlue 
@media (max-width: 1317px)
    .footer-wrapper
        padding-right: 0
@media (max-width: 767px)
    .footer-wrapper
        .footer
            flex-direction: column
            padding-bottom: 2rem
            >div
                width: 100%