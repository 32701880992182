


/* Webfont: Lato-Regular */@font-face {
    font-family: 'LatoWeb';
    src: 
         url('fonts/LatoLatin-Regular.woff2') format('woff2'), /* Modern Browsers */
         url('fonts/LatoLatin-Regular.woff') format('woff'); /* Modern Browsers */

    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
    font-display: swap;
}
