@import '../variables.sass'
.oh-container
    .oh-cards
        display: flex
        flex-wrap: wrap
        justify-content: center
        .day
            position: relative
            box-shadow: 0 6px 20px 0px rgba(0, 0, 0, 0.24)
            text-align: center
            background: white
            // height: 3px
            border-radius: 15px
            padding: 2rem 1rem
            width: 300px
            margin: 1%
            .form-control
                width: auto
            .configure-container
                position: absolute
                top: 1rem
                right: 1rem
                cursor: pointer
                text-align: right
                img
                    width: 1.5rem
                    height: 1.5rem
                .submenu
                    display: none
                    position: relative
                    bottom: -0.3rem
                    left: 0
                    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2)
                    .menu-item
                        padding: 0.35rem 1.5rem
                        background: #f9f9f9
                        text-align: left
                        &:first-child
                            // border-bottom: 1px solid #535353
                        &:hover
                            background: #f1f1f1
                            font-weight: bold
                        &.active
                            background: #f1f1f1
                            font-weight: bold
                    &.visible
                        display: block
            .day-name
                font-family: 'KelsonBold'
                margin-bottom: 1rem
                color: black
            .photo
                display: flex
                justify-content: center
                align-items: center 
                .img-wrapper
                    width: 150px
                    height: 150px
                    border-radius: 50%
                    // border: 2px solid black
                    margin-bottom: 1rem
                    overflow: hidden
            .disclaimer
                display: block
                color: $disclaimer
                font-size: 0.7rem
                line-height: 0.8rem
                margin-bottom: 0.5rem
            .name
                font-family: 'KelsonBold'
                margin-bottom: 0.5rem
                color: black
            .time
                margin-bottom: 0.5rem
                &.changed
                    color: $niceRed
                &.cancelled
                    color: $niceRed
                    font-family: 'KelsonNormal'
                &.crossed
                    .hours
                        text-decoration: line-through
                input
                    width: 50%
                    text-align: center
                    margin-left: auto
                    margin-right: auto
            .modify-container
                margin-top: 0.5rem
                text-align: left
                label
                    margin-bottom: 0
                .checkbox-container
                    display: flex
                    align-items: center
                    input
                        width: 16px
                .disclaimer
                    margin-top: 0
                input
                    margin-right: 0.5rem
            &.first
                .day-name
                    color: $esnDarkBlue
            &.second
                .day-name
                    color: $esnBlue
            &.third
                .day-name
                    color: $esnGreen
            &.fourth
                .day-name
                    color: $esnMagenta
            &.fifth
                .day-name
                    color: $esnOrange
                

// @media screen and ( max-width: 1200px)
//     .oh-container
//         .oh-cards
//             .day
//                 width: 45%
//                 margin-left: 2.5%
//                 margin-right: 2.5%