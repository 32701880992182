.list
    margin-top: 1rem
    border: 1px solid black
.waiting-esner
    display: flex
    align-items: center
    .name
        margin-right: 0.5rem
        font-weight: bold
        width: 200px
        border-right: 1px solid black
        padding: 0.25rem
    .email
        margin-right: 0.5rem
        width: 200px
        border-right: 1px solid black
        padding: 0.25rem
    button
        height: 1.5rem
        padding: 0