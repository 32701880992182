$esnBlue: #00AEEF
$esnMagenta: #EC008C
$esnGreen: #7AC143
$esnOrange: #F47B20
$esnDarkBlue: #2E3192
$esnDarkBlueTransparent: #2e3192e6
$esnOrangeTransparent: #f47b20ab
$danger: #d9534f
$niceBlue: #285db3
$niceRed: #C41414
$darkGray: #474747
$darkGray2: #2f2f2f
$mainAccent: $esnDarkBlue
$secondaryAccent: $esnOrange
$calendarBorderColor: #5c5c5c
$disclaimer: #90949c
$teamGray: #a0a0a0
$sidebarWidth: 250px
// $headerHeight: 65px