@import  '../variables.sass'

.flag-select
    padding: 0
.profile-wrapper
    .react-datepicker-wrapper
        width: 100%
    label
        color: $esnDarkBlue
        font-family: KelsonBold
    .have-account
        font-size: 1.2rem
        font-family: KelsonBold
        margin-bottom: 0.5rem
    .remove-account
        color: $niceRed

@media (max-width: 767px)
    .profile-wrapper
        overflow: hidden
        .form-left
            margin-bottom: 1rem