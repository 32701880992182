
/* Webfont: Lato-Bold */@font-face {
    font-family: 'KelsonBold';
    src: url('fonts/KelsonSans-Bold.woff2') format('woff2'), /* Modern Browsers */
         url('fonts/KelsonSans-Bold.woff') format('woff'); /* Modern Browsers */

    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
    font-display: swap;
}

/* Webfont: Lato-Light */@font-face {
    font-family: 'KelsonNormal';
    src:  url('fonts/KelsonSans-Normal.woff2') format('woff2'), /* Modern Browsers */
         url('fonts/KelsonSans-Normal.woff') format('woff'); /* Modern Browsers */
         
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
    font-display: swap;
}