@import '../variables.sass'
.page-container.home-container
    padding-right: 0
    max-width: calc(100% - 250px)
    padding-top: 0
    padding-bottom: 0
    .carousel-indicators
        margin-left: initial
        margin-right: initial
        padding-left: 2rem
        justify-content: flex-start
    .carousel
        overflow: hidden
    .carousel-indicators li
        width: 20px
        height: 20px
        border: 0px
        background: $esnDarkBlue
        border-radius: 50%
        opacity: 0.25
        &.active
            opacity: 1
    .background-item
        display: block
        // border: 1px solid grey
        max-height: calc(100vh - 65px)
        min-height: calc(100vh - 65px)
        max-width: 100vw
        background-size: cover
        background-position: center center
        background-repeat: no-repeat
        &.standard
            background-position: 50% 50%
        &.rekrutacja
            background-position: 50% 10%
        &.no-center-mobile
            background-position: initial
    .carousel-caption
        width: 100%
        bottom: 0
        left: 0
        right: unset
        padding-left: 2rem
        padding-right: 2rem
        padding-bottom: 2.5rem 
        text-align: left
        background: rgba(255, 255, 255, 0.55)
        color: black
        &:after
            content:''
            position: absolute
            bottom: calc(2.5rem + 7.5px)
            left: 2rem
            height: 2px
            width: 200px
            background: $esnDarkBlue
            border-radius: 15px
        h3
            font-family: KelsonBold
        p
            font-family: KelsonNormal
    .carousel-inner
        max-height: calc(100vh - 65px)
        overflow: initial
    .social-media-container
        padding-top: 2rem
        padding-bottom: 2rem
        overflow: hidden
        background: $esnDarkBlue
        color: white
        h4
            text-align: center
            font-family: KelsonBold
            margin-bottom: 2.25rem
        .backgrounds
            display: flex
            width: 120%
            margin: auto
            .text
                width: 100%
                height: 100%
                display: flex
                align-items: center
                justify-content: center
                color: white
                font-size: 200%
        .facebook-wrapper
            background: #4267B2
            width: 50%
            max-height: 300px
            transform: skew(-10deg)
            transition: width 0.5s
            cursor: pointer
            display: flex
            align-items: center
            justify-content: center
            border-right: 4px solid $esnDarkBlue
            overflow: hidden
            img
                width: 75px
            &:hover
                width: 65%
            &::after
                content: ""
                display: block
                padding-bottom: 100%
        .instagram-wrapper
            position: relative
            width: 50%
            // left: -2.5%
            transform: skew(-10deg)
            max-height: 300px
            background: #f09433
            background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%)
            background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%)
            background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%)
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1)
            cursor: pointer
            transition: width 0.5s, background 0.5s
            display: flex
            align-items: center
            justify-content: center
            overflow: hidden
            img.logo
                width: 75px
            &::after
                content: ""
                display: block
                padding-bottom: 100%
        .sm-wrapper
            box-shadow: 1px 1px 10px black
            .background
                position: absolute
                left: 0
                width: 100%
                height: 100%
                opacity: 0
                transition: opacity 0.5s
                background: #fafafa
                // filter: grayscale(80%) blur(3px)
                display: flex
                justify-content: center
                align-items: center
                .grayout
                    position: absolute
                    left: 0
                    top: 0
                    height: 100%
                    width: 100%
                    background: #ffffffa1
                    // background: #b9b9b996
                    z-index: 3
                    display: flex
                    justify-content: center
                    align-items: center
                    span
                        font-family: KelsonBold
                        font-size: 150%
                        margin-left: 2%
                        text-shadow: 4px 4px 12px #292929
                        text-transform: uppercase
                        color: #2f2f2f
                        letter-spacing: 1.5px
                    img
                        width: 50px
                        overflow: hidden
                        border-radius: 12px
                        box-shadow: 4px 4px 17px #292929
                img
                    position: relative
                    // top: -25%
                    transition: top 0.5s
                    // height: 100%
                    width: 100%
            &:hover
                width: 65%
                background: none
                .background
                    opacity: 1
                    img
                        // top: -35%
            &.facebook-wrapper
                .background
                    background: #eaebef
    .about-container
        background: white
        padding-top: 2rem
        padding-bottom: 2rem
        .image-container
            display: flex
            align-items: center
        >div
            margin-left: auto
            margin-right: auto
        h3
            font-family: KelsonBold
            display: flex
            align-items: center
            img
                width: 40px
                margin-right: 1rem
        .description
            text-align: justify
        .bold
            font-weight: 600
    .owl-carousel
        padding-top: 2rem
        padding-bottom: 2rem
        background: white
        .owl-item
            display: flex
            justify-content: center
            align-items: center
            height: 80px
            img
                width: 100px
@media (min-width: 1440px)
    .page-container.home-container
        .background-item
            &.standard
                background-position: 50% 30%
            &.rekrutacja
                background-position: 50% 50%
            &.high
                background-position: 50% 57.5%
    .page-container.home-container
        .background-item
            max-height: 70vh
            min-height: 70vh
        .carousel-inner
            max-height: 70vh
@media (max-width: 1317px)
    .page-container.home-container
        padding-right: 0
        max-width: 100%
@media (max-width: 1024px) and (orientation:portrait)
    .page-container.home-container
        .background-item
            max-height: 40vh
            min-height: 40vh
        .carousel-inner
            max-height: 40vh
@media (max-width: 767px)
    .page-container.home-container .social-media-container .backgrounds
        margin-left: -10%
    .page-container.home-container .carousel-caption
        height: 210px
        bottom: -210px
    .page-container.home-container
        .carousel-caption
            p
                font-size: 16px
                line-height: 18px
                max-height: 36px
                overflow: hidden
                
    .page-container.home-container .carousel-inner
        margin-bottom: 210px
@media (max-width: 600px)
    .page-container.home-container
        .background-item
            max-height: 300px
            min-height: unset
            height: 56.25vw
        .carousel-inner
            max-height: 300px
        .carousel-control-next, .carousel-control-prev
            height: 250px
@media (max-width: 480px)
    .page-container.home-container 
        .social-media-container
            .sm-wrapper
                .background
                    .grayout
                        span
                            font-size: 120%
                        img
                            width: 40px
                            overflow: hidden
                            border-radius: 10px
                            box-shadow: 4px 4px 17px #292929