@import '../variables.sass'

/* Register Form */
.register-container
    max-width: 600px
    background: white
    padding-top: 2rem
    padding-bottom: 4rem
    border-radius: 20px
    box-shadow: 0 6px 20px 0 rgba(0,0,0,.24)
    .page-title
        color: $mainAccent
    .register-form
        width: 90%
        margin-left: auto
        margin-right: auto
        .react-datepicker-wrapper
            width: 100%
        .privacy-container
            display: flex
            justify-content: space-between
            align-items: center
            .left
                display: flex
                align-items: center
            input
                width: 1rem
                margin-right: 0.5rem
        .have-account
            width: 100%
        .flag-select 
            // padding: 0.375rem 0
            padding: 0
            .selected--flag--option
                padding-left: 0.75rem
                padding-right: 0
                .country-label
                    padding-left: 0
            img
                top: 0
                margin-right: 0.5rem
            .arrow-down
                float: right
            .flag-options
                width: 100%
                // margin-left: -0.75rem
            .filterBox input
                display: block
                width: 92%
                height: calc(1.5em + .75rem + 2px)
                padding: .375rem .75rem
                font-size: 1rem
                font-weight: 400
                line-height: 1.5
                color: #495057
                background-color: #fff
                background-clip: padding-box
                border: 1px solid #ced4da
                border-radius: .25rem
                transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out
        .form-error
            font-family: KelsonNormal
        label
            color: $mainAccent
            font-family: KelsonNormal
        .btn
            margin-top: 1rem
            border: $mainAccent
            background: $mainAccent
            width: 25%
            &:active
                border: $mainAccent
                background: $mainAccent

.login-box
    .login-button
        // background: $esnBlue
        // margin: 50px
        // cursor: pointer

// #googleButton 
    // display: inline-block
    // background: white
    // color: #444
    // // width: 190px
    // border-radius: 5px
    // border: thin solid #888
    // box-shadow: 1px 1px 1px grey
    // white-space: nowrap
    // &:hover 
    //     cursor: pointer
    // span.label 
    //   font-family: serif
    //   font-weight: normal
    // span.icon 
    //   background: url('/identity/sign-in/g-normal.png') transparent 5px 50% no-repeat
    //   display: inline-block
    //   vertical-align: middle
    //   width: 42px
    //   height: 42px
    // span.buttonText 
    //   display: inline-block
    //   vertical-align: middle
    //   padding-left: 42px
    //   padding-right: 42px
    //   font-size: 14px
    //   font-weight: bold
    //   /* Use the Roboto font that is loaded in the <head> */
    //   font-family: 'Roboto', sans-serif
    // .abcRioButtonBlue
    //     background-color: #4285f4
    //     border: none
    //     color: #fff
    //     height: 50px
    //     width: 240px
    //     border-radius: 1px
    //     -webkit-box-shadow: 0 2px 4px 0px rgba(0,0,0,.25)
    //     box-shadow: 0 2px 4px 0 rgba(0,0,0,.25)
    //     -webkit-box-sizing: border-box
    //     box-sizing: border-box
    //     -webkit-transition: background-color .218s,border-color .218s,box-shadow .218s
    //     transition: background-color .218s,border-color .218s,box-shadow .218s
    //     -webkit-user-select: none
    //     -webkit-appearance: none
    //     background-image: none
    //     cursor: pointer
    //     outline: none
    //     overflow: hidden
    //     position: relative
    //     text-align: center
    //     vertical-align: middle
    //     white-space: nowrap
    //     &:hover
    //         background-color: #4285f4
    //         box-shadow: 0 0 3px 3px rgba(66,133,244,.3)
    //     &:active
    //         background-color: #3367d6
    // .abcRioButtonContentWrapper
    //     border: 1px solid transparent
    //     height: 100%
    //     width: 100%
    // .abcRioButtonContents
    //     font-family: Roboto,arial,sans-serif
    //     font-weight: 500
    //     letter-spacing: .21px
    //     margin-left: 6px
    //     margin-right: 6px
    //     vertical-align: top
    //     font-size:16px
    //     line-height:48px
    //     display: block
    //     text-align: center
    // .abcRioButtonIcon
    //     padding: 15px
    //     border-radius: 1px
    //     background: #fff
    //     float: left
    //     .img-wrapper
    //         width: 18px
    //         height: 18px
    //         position: relative
    //         top: -3px
.fb 
    font-family: Lucida Grande, Helvetica Neue, Helvetica, Arial, sans-serif
    font-size: 14px
    padding: 13px 30px 15px 44px
    background: #3A5A97
    color: #fff
    text-shadow: 0 -1px 0 rgba(0,0,20,.4)
    text-decoration: none
    line-height: 1
    position: relative
    border-radius: 5px
    width: 240px
    height: 50px
    display: flex
    align-items: center
.connect:before 
    display: inline-block
    position: relative
    background-image: url("../../images/ikony/facebookLoginIcon.jpg")
    height: 23px
    background-repeat: no-repeat
    background-position: 0px 3px
    text-indent: -9999px
    text-align: center
    width: 29px
    line-height: 23px
    margin: -8px 7px -7px -30px
    padding: 2 25px 0 0
    content: "f"
.login-button
    background: white
    border: 1px solid rgb(168, 168, 168)
    display: flex
    width: 220px
    height: 40px
    padding: 8px 16px
    border-radius: 4px
    margin-top: 20px
    cursor: pointer
    margin-left: auto
    margin-right: auto
    font-size: 90%
    align-items: center
    text-decoration: none
    color: black
    &:hover
        border-color: rgb(117, 117, 117)
        color: rgb(41, 41, 41)
        text-decoration: none
    img
        margin-right: 10px
        width: 18px
        height: 18px
.no-account
    margin-top:30px
    a
        width: 100%
        text-align: center
        color: black
        span
            font-weight: bold
            color: #007bff
    &.no-margin
        margin-top: 10px
        a
            color: #007bff
            font-weight: bold
    &:hover
        a
            text-decoration: none
            span
                text-decoration: underline

@media screen and ( max-width: 767px)
    .register-container
        background: transparent
        padding-top: 0
        box-shadow: none
        .register-form
            width: 100%
            .form-left
                margin-bottom: 1rem

    