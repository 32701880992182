@use './components/_calendar.sass'
@use './components/_header.sass'
@use './components/_footer.sass'
@use './components/_sidebar.sass'
@use './components/_pageNotFound.sass'
@use './components/_register.sass'
@use './components/_swUpdate.sass'
@use './components/_singleEvent.sass'
@use './components/_faq.sass'
@use './components/_createOrEditEvent.sass'
@use './components/_tasks.sass'
@use './components/_officeHours.sass'
@use './components/_manageUsers.sass'
@use './components/_profile.sass'
@use './components/_erasmusMap.sass'
@use './components/_team.sass'
@use './components/_home.sass'
@use './components/_articles.sass'
@use './components/_progressiveImage.sass'
@import 'latofonts.css'
@import 'kelsonfonts.css'
@import "~react-datepicker/dist/react-datepicker.css"
@import 'variables.sass'


.dark-blue-button
    color: white
    background: $esnDarkBlue
    border: 0
    min-width: 10rem
    &:active
        background: $esnDarkBlue !important
        box-shadow: 0 0 0 0.2rem rgba(38,143,255,.5)
    &:focus
        box-shadow: 0 0 0 0.2rem rgba(38,143,255,.5)
    &:hover
        color: white
.dark-gray-button
    color: white
    background: $darkGray2
    border: 0
    min-width: 10rem
    &:active
        background: $darkGray2 !important
        box-shadow: 0 0 0 0.2rem rgba(38,143,255,.5)
    &:focus
        box-shadow: 0 0 0 0.2rem rgba(38,143,255,.5)
    &:hover
        color: white
        box-shadow: 0 0 0 0.2rem rgba(47,47,47,.5)
html
    font-size: 100%
img
    max-width: 100%
    height: auto
body
    background: #f2f2f2
    background: #e9ebee !important
    font-family: LatoWeb
    font-size: 1rem
    // padding-bottom: 2rem
    margin-top: 65px
@media (max-width: 767px)
    #fb-root
        .fb_dialog_content
            iframe[data-testid="bubble_iframe"]
                bottom: 66px !important
                right: 0 !important
                transition: bottom 0.3s ease-in-out
            iframe[data-testid="unread_iframe"]
                bottom: 110px !important
                right: 10px !important
            &.menu-open
                iframe[data-testid="bubble_iframe"]
                    bottom: 126px !important
                iframe[data-testid="unread_iframe"]
                    bottom: 170px !important
.page-container
    padding-top: 2rem
    padding-bottom: 5rem
    padding-right: $sidebarWidth
    //footer height + header height + footer top margin
    min-height: calc(100vh - 180px - 65px)
    // max-width: 1300px
.construction
    text-align: center
    h2
        margin-bottom: 2rem
span
    word-break: break-word
.custom-m-r-1
    margin-right: 1rem
.tox .tox-notification--warn, .tox .tox-notification--warning
    display: none !important
.visible-xs
    display: none
.pull-to-refresh
    display: none
.loading-container
    text-align: center
    height: 60vh
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column
    min-height: calc(100vh - 180px - 65px - 5rem)
    .loading-text
        margin-top: 2rem
    &.full
        img
            width: 400px
            max-width: unset
.react-datepicker-popper
    z-index: 55
.text-overflow
    text-overflow: ellipsis
    white-space: nowrap
    overflow: hidden
header.header
    position: fixed
    top: 0
    width: 100%
    display: flex
    align-items: center
    height: 65px
.container-80
    max-width: 80%
    margin-left: auto
    margin-right: auto

.page-title
    margin-bottom: 40px
    text-align: center
    font-family: KelsonBold
.kelson-normal
    font-family: 'KelsonNormal'
.kelson-bold
    font-family: KelsonBold
.rotating
    -webkit-animation: rotating 3s linear infinite
    -moz-animation: rotating 3s linear infinite
    -ms-animation: rotating 3s linear infinite
    -o-animation: rotating 3s linear infinite
    animation: rotating 3s linear infinite
.construction-container
    font-family: 'LatoWeb'

// Loading spinner
.lds-ring 
    display: inline-block
    position: relative
    width: 80px
    height: 80px
.lds-ring div 
    box-sizing: border-box
    display: block
    position: absolute
    width: 64px
    height: 64px
    margin: 8px
    border: 8px solid $esnBlue
    border-radius: 50%
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite
    border-color: $esnBlue transparent transparent transparent
.lds-ring div:nth-child(1) 
    animation-delay: -0.45s
.lds-ring div:nth-child(2) 
    animation-delay: -0.3s
.lds-ring div:nth-child(3) 
    animation-delay: -0.15s
@keyframes lds-ring 
    0% 
        transform: rotate(0deg)

    100% 
        transform: rotate(360deg)



.esn-logo
    max-width: 100px
.esn-logo-main
    max-width: 300px
.down-container
    padding-left: 2rem
    padding-right: 2rem
.error-text
    color: $danger
    font-weight: bold

.icon
    height: 1rem
    width: 1rem
    margin-right: 1rem
    position: relative
    top: -2px


.star-header
    font-family: KelsonBold
    display: flex
    align-items: center
    text-align: left
    img
        width: 40px
        margin-right: 1rem

.form-error
    text-align: right
    color: $danger



@keyframes rotating
    0%
        -webkit-transform: rotate(0deg)
        -o-transform: rotate(0deg)
        transform: rotate(0deg)
    100%
        -webkit-transform: rotate(360deg)
        -o-transform: rotate(360deg)
        transform: rotate(360deg)
@media (min-width: 1318px)
    .page-container
        max-width: 1250px
    // .loading-container
    //     max-width: 1250px
    //     margin-left: auto
    //     margin-right: auto
    //     padding-right: 250px
@media (max-width: 1317px)
    .page-container
        padding-right: 15px
    //this is messed up
    .page-container.fix-pad
        padding-right: 0
@media screen and (max-width: 1200px)
    .add-padding.container
        max-width: 100%
//MD is 768px+!!!, I'll style tablet same as desktop. Anything below = mobile (xs + sm)
@media screen and ( max-width: 767px)
    body
        margin-top: 0
        margin-bottom: 60px
        // margin-bottom: 7rem
        // padding-bottom: 2rem
    .hidden-xs
        display: none
    .visible-xs
        display: block
    .add-padding.container
        padding: 0px 15px 0px 15px
        background: #454545
        margin-bottom: 3rem
        box-shadow: 0 0 34px 15px #454545
        overflow: hidden
    .page-container, .loading-container
        min-height: initial
    .loading-container.full
        img
            width: 300px
    .page-container.tasks-container
        min-height: calc(100vh - 180px - 65px - 15rem)
    .faq-container, .page-container
        padding-top: 0
        max-width: none
    .page-container.esncard-container
        padding-top: 2rem
    .calendar-container
        margin-left: -15px
        margin-right: -15px
    .page-title
        margin-left: -15px
        margin-right: -15px
        padding: 1rem
        // min-height: 100px
        display: flex
        align-items: center
        justify-content: center
    .forms-container
        .page-title
            background: $esnDarkBlue
            color: white
    .privacy-container
        .page-title
            background: $darkGray2
            color: white
    .faq-container
        .page-title
            background: $esnOrange
            color: white
    .oh-container
        .page-title
            background: $esnBlue
            color: white
    .team-container
        .page-title
            background: $esnBlue
            color: white
    .map-container
        .page-title
            background: $esnGreen
            color: white
    .manage-event-container
        .page-title
            background: $esnDarkBlue
            color: white
            margin-left: -30px
            margin-right: -30px
    .register-container
        .page-title
            background: $esnDarkBlue
            color: white
            margin-left: -30px
            margin-right: -30px
    .tasks-container
        .page-title
            background: $esnDarkBlue
            color: white
    .custom-pl-mobile-0
        padding-left: 0 !important
    .custom-pr-mobile-0
        padding-right: 0 !important
    .custom-ml-mobile-0
        margin-left: 0 !important
    .custom-mr-mobile-0
        margin-right: 0 !important
    .event-form
        .form-left
            margin-bottom: 1rem
        .form-error
            padding-right: 0
        .label-container
            padding-left: 0
        .dormitory-container
            padding-left: 0
            justify-content: flex-start
            label
                padding-right: 2rem

    .mobile-container
        width: 100%
        margin-right: auto
        margin-left: auto
        padding-left: 30px
        padding-right: 30px
        &.profile-wrapper
            padding-left: 15px
            padding-right: 15px

    