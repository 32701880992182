@import '../variables.sass'


    
/* Calendar page */
.add-padding
    padding: 30px 45px
.list-header
    text-align: center
    margin-bottom: 2rem
.calendar-heading
    display: flex
    flex-direction: row
    justify-content: space-between
    margin-bottom: 1rem
    margin-bottom: 0
    align-items: center
    padding: 0.8rem 1.6rem
    background: #2f2f2f
    box-shadow: 0px -2px 14px -1px #2f2f2f
    border-top-left-radius: 20px
    border-top-right-radius: 20px
    .date
        font-size: 1.5rem
        font-family: KelsonBold
        color: white
    .arrow img
        cursor: pointer
        height: 30px
        width: 30px
.esn-pending
    min-height: 50px
    display: flex
    justify-content: center
    align-items: center
    font-size: 1.25rem
    font-family: KelsonNormal
    text-align: center
    padding-bottom: 2rem
    position: relative
    &.hidden
        display: none !important
    .star-container
        width: 60px
        height: 60px
        margin-right: 0.5rem
        img
            width: 60px
            height: 60px
            max-width: initial
    .text
        padding: 1rem
    .cross
        position: absolute
        top: 0rem
        right: 0rem
        cursor: pointer
        img
            height: 15px
            width: 15px


.calendar-footer
    padding: 0.8rem 1.6rem
    background: #2f2f2f
    box-shadow: 0px 6px 14px -1px #2f2f2f
    border-bottom-left-radius: 20px
    border-bottom-right-radius: 20px
    display: flex
    justify-content: center
    .btn
        color: white
        background: $esnOrange
        &:focus
            box-shadow: 0 0 0 0.2rem rgba(244, 123, 32, 0.5)
        &:hover
            box-shadow: 0 0 0 0.2rem rgba(244, 123, 32, 0.5)
.calendar-container
    padding-bottom: 45px
.calendar
    padding: 0px
    display: flex
    flex-wrap: wrap
    overflow: hidden
    background: #454545
    box-shadow: 0px -2px 14px -1px #2f2f2f
    .weekDay
        min-width: 14.285%
        max-width: 14.285%
        border-bottom: 1px solid #5c5c5c
        border-right: 1px solid #5c5c5c
        text-align: center
        font-family: KelsonBold
        padding: 0.4rem
        color: $esnOrange
        background: #393939fa
        // color:
    .day:nth-last-child(-n+7)
        border-bottom: 0
    .day
        color: white
        min-width: 14.285%
        max-width: 14.285%
        min-height: 100px
        max-height: 195px
        border-style: solid
        border-color: $calendarBorderColor 
        border-width: 0 1px 1px 0
        font-size: 0.9rem
        cursor: pointer
        .indicator
            font-size: 1.2rem
            padding-top: 5px
            font-family: LatoWeb
            font-weight: bold
            height: 40px
            padding-left: 10px
        .all-events
            overflow: auto
            max-height: 155px
            width: calc(100% + 2px)
        &.current-day
            .indicator
                color: $esnOrange
        &:hover
            // border: 0
            background: #5b5b5b
            .indicator
                background: #5b5b5b
        &.selected
            background: #5b5b5b
            .indicator
                background: #5b5b5b
        .multiday-events
            .multiday-event 
                position: relative
                margin-bottom: 0.6rem
                border-radius-left: 5px
                font-weight: bold
                color: white
                background: $esnOrange
                left: -1px
                font-family: LatoWeb
                letter-spacing: 0.5px
                max-height: 20px
                overflow: hidden
                z-index: 55
                &.esn-only
                    background: $esnBlue
                &.zarzad-only
                    background: $niceRed
                span
                    padding-left: 10px
                &.first-day
                    margin-left: 10px
                    border-top-left-radius: 10px
                    border-bottom-left-radius: 10px
                &.last-day
                    border-top-right-radius: 10px
                    border-bottom-right-radius: 10px
                    margin-right: 10px
        .events-of-day
            overflow-y: auto
            overflow-x: hidden

            .event
                padding-left: 5px
                margin-bottom: 0.6rem
                font-weight: 700
                letter-spacing: 0.5px
                padding-left: 12px
                padding-right: 5px
                position: relative
                color: white
                &::before
                    content: ''
                    position: absolute
                    height: 100%
                    width: 4px
                    background: $esnOrange
                    left: 0px
                .time
                    font-family: KelsonNormal
                    letter-spacing: 2px
                    color: $esnOrange
                    font-weight: bold
                &.esn-only
                    .time
                        color: $esnBlue
                    &::before
                        background: $esnBlue
                &.zarzad-only
                    .time
                        color: $niceRed
                    &::before
                        background: $niceRed

        &.other-month-day
            color: #8e8e8e
            .multiday-events
                .multiday-event
                    background: $esnOrangeTransparent
                    color: #bbbaba

.calendar .day.other-month-day .multiday-events .multiday-event.esn-only
    background: #0978A2
.events-list
    text-align: center
    margin: auto
    display: flex
    flex-wrap: wrap
    justify-content: space-between
    .no-events
        width: 100%
.event-wrapper
    width: 280px
    height: 360px
    margin-left: auto
    margin-right: auto
    border-radius: 10px
    overflow: hidden
    position: relative
    margin-bottom: 2rem
    box-shadow: 0 6px 20px 0px rgba(0, 0, 0, 0.24)
    transition: all 0.3s ease-in-out
    &:hover
        box-shadow: 0 6px 20px 2px rgba(244, 123, 32, 0.27)
        transform: scale(1.05)
    &.esn-only
        &:hover
            box-shadow: 0 6px 20px 2px rgba(0, 174, 239, 0.27)
    &.zarzad-only
        &:hover
            box-shadow: 0 6px 20px 2px rgba(196, 20, 20, 0.27)
    &.past-event
        filter: grayscale(1)
        &:hover
            filter: grayscale(0)
    .image-wrapper
        height: 155px
        .banner-img
            // height: 160px
            // width: 100%
            height: 100%
            width: 100%
            // object-fit: cover
    .user-counter
        position: absolute
        bottom: 0
        left: 0
        height: 0
        width: 0
        border-bottom: 85px solid $niceRed
        border-right: 85px solid transparent
        font-size: 1rem
        color: black
        .inside
            color: white
            display: flex
            flex-direction: column
            align-items: center
            width: 50px
            height: 50px
            bottom: -36px
            left: 0
            position: relative
            font-family: KelsonBold
            img
                width: 25px
                height: 25px
    .link
        display: block
        height: 100%
        width: 100%
        color: black
        .description
            text-align: left
            background: white
            height: 205px
            color: black
            padding: 5px 10%
            font-size: 80%
            .separator
                height: 1px
                width: 85%
                background: $esnOrange
                margin-left: auto
                margin-right: auto
                margin-top: 0.5rem
                margin-bottom: 1rem
                &.esn-only
                    background: $esnBlue
                &.zarzad-only
                    background: $niceRed
            .name
                text-overflow: ellipsis
                white-space: nowrap
                overflow: hidden
                width: 100%
                padding-top: 0.5rem
                padding-bottom: 0.5rem
                color: black
                text-align: left
            >div
                margin-bottom: 0.5rem
                &.date, &.location
                    display: flex
                    align-items: center
                    overflow: hidden
                    .icon
                        top: 0
            .see-more
                position: absolute
                left: calc(50% - 75px)
                bottom: 15px
                margin-left: auto
                margin-right: auto
                background: $esnOrange
                padding: .25em 1em
                width: 150px
                text-align: center
                border-radius: 5px
                color: #fff
                font-weight: 500
                letter-spacing: .2px
                font-size: 17px
                margin-top: 2rem
                &:hover
                    box-shadow: 0 0 0 0.2rem rgba(244, 123, 32, 0.5)
                &.esn-only
                    background: $esnBlue
                    &:hover
                        box-shadow: 0 0 0 0.2rem rgba(0, 174, 239, 0.5)
                &.zarzad-only
                    background: $niceRed
                    &:hover
                        box-shadow: 0 0 0 0.2rem rgba(196, 20, 20, 0.5)
        &:hover
            text-decoration: none
    .overlay
        position: absolute 
        width: 100%
        height: 100%
        z-index: 10

// @media (min-width: 1318px)
//     .event-wrapper
//         &.past-event
//             filter: grayscale(1)
//             &:hover
//                 filter: grayscale(0)
@media (max-width:992px)
    .calendar-col
        max-width: 100%
@media screen and ( max-width: 767px)
    .esn-pending
        font-size: 1rem
        .star-container
            margin-right: 0
    .calendar-heading, .calendar-footer
        margin-bottom: 0
        // margin-left: -15px
        // margin-right: -15px
        border-radius: 0
        box-shadow: unset
        .arrow img
            width: 20px
            height: 20px
    
    .calendar-footer
        box-shadow: 0px -2px 19px 3px #2f2f2f
    .calendar
        padding-bottom: 30px
        border: 0
        box-shadow: unset
        .weekDay
            padding: 1rem 0 0 0
            border: 0
            font-size: 0.8rem
            background: none
            color: $esnOrange
            font-family: KelsonBold
        .day
            color: white
            position: relative
            min-height: 0px
            border: none
            overflow: hidden
            &.other-month-day
                color: #8e8e8e
            .indicator
                font-size: 1rem
                display: flex
                align-items: center
                justify-content: center
                // height: 50px
                padding: 0
                text-align: center
                padding-left: 0
                border-radius: 50%
                padding-top: 100%
                position: relative
                span
                    position: absolute
                    left: 0
                    right: 0
                    top: 0
                    bottom: 0
                    display: flex
                    align-items: center
                    justify-content: center
            .all-events-mobile
                position: absolute
                bottom: 15%
                display: flex
                width: 100%
                justify-content: center
                &.has-events
                    position: absolute
                    height: 9px
                    width: 9px
                    border-radius: 50%
                    background: $esnDarkBlue
                    margin: auto
                    bottom: 5px
                    left: 0
                    right: 0
                .dot
                    width: 7px
                    height: 7px
                    border-radius: 50%
                    margin-left: 2px
                    margin-right: 2px
                .dot:nth-child(1)
                    background: $esnOrange
                    &.esn-only
                        background: $esnBlue
                    &.zarzad-only
                        background: $niceRed
                // .dot:nth-child(2)
                //     background: $esnGreen
                // .dot:nth-child(3)
                //     background: $esnOrange
                    
                
            .multiday-events
                .multiday-event
                    width: 10px
                    height: 10px
            &.current-day
                border-bottom: 0
                // background: #5b5b5b
                border-radius: 50%
                .indicator
                    color: $esnOrange
            &:hover
                // border: 0
                background: unset
                .indicator
                    background: #5b5b5b
            &.selected
                border-radius: 50%
                .indicator
                    background: #5b5b5b


