@import '../variables.sass'
.page-container.map-container
    max-width: none !important
    padding-right: 250px
    padding-left: 0
    padding-top: 0
    padding-bottom: 0
    overflow: hidden
    div[role="button"]
        outline: 0 !important
.info-window
    min-width: 180px
    max-width: 250px
    .heading
        display: flex
        justify-content: space-between
        align-items: center
        font-family: 'KelsonBold'
        margin-bottom: 5px

        h5
            margin-right: 2rem
            // line-height: 42px
            font-size: 1.1rem
        img
            height: 28px
            max-height: 28px
    .description
        margin-top: 0.5rem
        font-family: 'LatoWeb'
    .address
        font-family:'LatoWeb'
        font-weight: 800
    .website
        margin-top: 5px
.gm-style .gm-style-iw-c
    top: -50px
.gm-style .gm-style-iw-t::after
    top: -50px
#side-toggle
    width: 60px
    height: 60px
    background: white
    display: flex
    align-items: center
    justify-content: center
    position: absolute
    right: 249px
    top: 0
    z-index: 4
    transition: right 0.3s ease-in-out
    background: white
    cursor: pointer
    // box-shadow: 0 0 8px 0px black
    &.th-toggle
        font-size: 12px
        font-weight: 600
        padding-left: 5px
        padding-right: 5px
        text-align: center
        color: white
        letter-spacing: 0.5px
        background: #f47b20
    img
        width: 25px
        height: 25px
        transition: transform 0.3s ease-in-out
    &.active
        right: 549px
        img
            transform: rotateY(180deg)
.confetti-wrapper
    position: absolute !important
    top: 20%
    left: 50%
    transform: translateX(-50%)
    z-index: 55
#sidebar-map
    position: absolute
    background: white
    right: -50px
    width: 300px
    z-index: 4
    transition: right 0.3s ease-in-out
    height: calc(100vh - 65px)
    .password-wrapper
        padding-left: 15px
        padding-right: 15px
        text-align: center
        h5
            margin-top: 1rem
            margin-bottom: 1rem
            font-weight: 600
        label
            margin-bottom: 0
        button
            margin-top: 1rem
            min-width: 140px
    .places
        height: calc(100vh - 65px - 60px)
        overflow: auto
        padding-bottom: 15px
    .select-category
        height: 50px
        display: flex
        align-items: center
        justify-content: center
        margin-top: 5px
        margin-bottom: 5px
    &.active
        right: 249px
    select
        width: 80%
        margin-left: auto
        margin-right: auto
        // margin-top: 20px
    .disclaimer
        font-size: 75%
        color: #adadad
    .item
        padding: 10px
        padding-right: 15px
        display: flex
        height: 120px
        overflow: hidden
        cursor: pointer
        position: relative
        transition: background 0.3s ease-in-out
        .left-background
            position: absolute
            left: 0
            top: 0
            height: 100%
            width: 3px
            transition: width 0.3s ease-in-out
            background: $esnBlue
            &.entertainment
                background: $esnOrange
            &.history
                background: $niceRed
            &.food
                background: $esnBlue
            &.other
                background: $esnDarkBlue
            &.nature
                background: $esnGreen
        .icon-map
            width: 40px
            max-width: 40px
            min-width: 40px
            display: flex
            align-items: center
            justify-content: center
            margin-right: 5px
            img
                width: 35px
                max-width: unset
        .text
            overflow: hidden
            .name
                font-weight: 800
            .description
                line-height: 20px
                font-size: 14px
                height: 75px
                text-align: justify
        &:hover
            background: #dadada85
            .left-background
                width: 7px
@media (max-width: 1317px)
    .page-container.map-container
        //not sure what that was for?
        // padding-right: 15px
        padding-right: 0
        overflow: hidden
    #sidebar-map
        right: -300px
        &.active
            right: 0
        .item
            padding-right: 15px
    #side-toggle
        right: 0
        &.active
            right: 300px
@media (max-width: 767px)
    #sidebar-map .item .text .description
        line-height: 19px
        font-size: 14px
        height: 72px
    #sidebar-map .places
        height: calc(100vh - 65px - 60px - 60px)
        padding-bottom: 15px
    .map-container
        &>div
            &:not(#side-toggle):not(#sidebar-map)
                height: calc(100vh - 65px - 60px) !important
    .map-container .page-title
        margin-bottom: 0
    .page-container.map-container
        padding-left: 0
        padding-right: 0
        overflow: hidden
        padding-bottom: 0
@media (max-width: 360px)
    #sidebar-map
        right: calc(-100vw + 60px)
        width: calc(100vw - 60px)
        &.active
            right: 0
    #side-toggle
        &.active
            right: calc(100vw - 60px)
    .map-container
        .info-window
            min-width: unset