@import '../variables.sass'
    
.tasks-list
    display: flex
    flex-wrap: wrap
    margin-bottom: 2rem
    justify-content: center
    .event
        margin-bottom: 1rem
        width: 45%
        margin-left: 2.5%
        margin-right: 2.5%
        // padding: 1rem
        background: white
        box-shadow: 0 6px 20px 0px rgba(0, 0, 0, 0.24)
        border-radius: 15px
        .title
            color: white
            background: $esnDarkBlue
            border-top-left-radius: 15px
            border-top-right-radius: 15px
            padding: 1rem 1rem 0 1rem
            .time
                font-size: 1.2rem
                padding-bottom: 0.5rem
                // border-bottom: 1px solid $esnDarkBlue
                margin-bottom: 1rem
                color: #a2a2a2
                display: flex
                align-items: center
                font-family: KelsonNormal
                img
                    width: 1.35rem
                    height: 1.35rem
                    margin-right: 0.5rem
            .name
                font-family: KelsonNormal
        &.zarzad-only
            .title
                background: $niceRed
                .time
                    color: #a2a2a2
        .content
            padding: 0 1rem 1rem 1rem
            min-height: 200px
            .task
                .btn
                    margin-top: -20px
                    margin-left: 1.5rem
                    padding: 0.2rem 1rem
                    background: $esnDarkBlue
                    border: 0
                    &:active
                        background: $esnDarkBlue !important
                        box-shadow: 0 0 0 0.2rem rgba(38,143,255,.5)
                    &:focus
                        box-shadow: 0 0 0 0.2rem rgba(38,143,255,.5)
                .volunteers
                    // padding-left: 4rem
                    list-style: decimal
                    .resign-cross
                        width: 0.65rem
                        margin-left: 0.65rem
                        cursor: pointer
                .task-name 
                    font-size: 1.2rem
                    display: flex
                    align-items: center 
                    .list-icon
                        width: 0.8rem
                        // margin-left: 1rem
                        margin-right: 0.5rem
        // .footer
        //     margin-top:1rem
        //     height: 2rem
        //     background: $esnDarkBlue
        //     padding: 0 1rem 1rem 1rem
        //     border-bottom-left-radius: 15px
        //     border-bottom-right-radius: 15px

@media screen and ( max-width: 767px)
    .tasks-list
        .event
            width: 95%
                