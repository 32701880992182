@import '../variables.sass'

.faq-container
    padding-top: 2rem
    .disclaimer
        color: #6b7d8e8c
        margin-top: 3rem
        font-size: 0.8rem
        text-align: right
section    
    .section-title
        color: gray
        font-size: 1.2rem
        font-family: KelsonBold
        margin-top: 1rem
        margin-bottom: 0.5rem
    .question
        background: white
        padding: 1rem 1.5rem
        margin-top: 0.75rem
        cursor: pointer
        border-radius: 10px
        &:hover
            box-shadow: 0 1px 10px #cacaca
        .title
            position: relative
            color: $esnOrange
            font-size: 1.2rem
            font-family: KelsonNormal
            width: calc(100% - 32px)
            &:before, &:after
                content: ''
                position: absolute
                right: -28px
                top: 50%
                height: 2px
                width: 13px
                background: $esnOrange
                -webkit-backface-visibility: hidden
                backface-visibility: hidden
                transition: -webkit-transform .2s
                transition: transform .2s
                transition: transform .2s,-webkit-transform .2s
                -webkit-transform: rotate(-45deg)
                -ms-transform: rotate(-45deg)
                transform: rotate(-45deg)
            &:before
                -webkit-transform: rotate(45deg)
                -ms-transform: rotate(45deg)
                transform: rotate(45deg)
                right: -20px
        &.show
            .answer
                margin-top: 0.5rem
            .title
                &:before
                    -webkit-transform: rotate(-45deg)
                    -ms-transform: rotate(-45deg)
                    transform: rotate(-45deg)
                &:after
                    -webkit-transform: rotate(45deg)
                    -ms-transform: rotate(45deg)
                    transform: rotate(45deg)
        .answer
            color: #6b7d8e
            overflow: hidden
            transition: all 0.45s ease-in-out
            height: 0
            font-size: 0.9rem
            // margin-bottom: -2000px
            // transition: margin-bottom 0.3s cubic-bezier(1, 0, 1, 1), visibility 0s 0.3s, max-height 0s 0.3s
            // visibility: hidden
            // max-height: 0
            // &.show
            //     max-height: 1000px
            //     visibility: visible
            //     margin-bottom: 0
    &.polish
        .title
            color: $esnBlue
            &:before, &:after
                background: $esnBlue
        .question
            box-shadow: 0 0px 5px #cacaca
            margin-top: 1rem
            &:hover
                box-shadow: 0 0px 15px #cacaca
    &.compact
        .question
            box-shadow: none
            margin-top: 0
            border-radius: 0
            border: 1px solid #e0e0e0
            &:hover 
                box-shadow: none

.polish-article-wrapper
    a
        color: $esnBlue
        font-weight: 600
.polish-faq
    margin-top: 30px
    h3
        text-align: center
        font-family: KelsonBold
        margin-bottom: 30px


@media screen and (max-width: 767px)
    section
        .answer
            font-size: 0.8rem
        .question
            .title
                width: 90%
                font-size: 1rem
                &:before
                    right: -32px
                    -webkit-transform: rotate(-45deg)
                    -ms-transform: rotate(-45deg)
                    transform: rotate(-45deg)
                &:after
                    right: -24px
                    -webkit-transform: rotate(45deg)
                    -ms-transform: rotate(45deg)
                    transform: rotate(45deg)
            &.show
                .title
                    &:before
                        -webkit-transform: rotate(45deg)
                        -ms-transform: rotate(45deg)
                        transform: rotate(45deg)
                    &:after
                        -webkit-transform: rotate(-45deg)
                        -ms-transform: rotate(-45deg)
                        transform: rotate(-45deg)