@import '../variables.sass'

.sidebar
    position: fixed
    right: 0
    top: 65px
    background: #e9ebee
    width: $sidebarWidth
    height: calc(100% - 65px)
    border-left: 1px solid rgba(156, 156, 156, .4)
    box-shadow: inset 2px 0 2px -2px #b2b9c9
    z-index: 5
    .wrapper
        overflow: auto
        height: 100%
        .events-today
            padding-bottom: 1rem
            border-bottom: 1px solid rgba(156,156,156,.4)
            .time, .location
                display: flex
                overflow: hidden
        .today
            margin-bottom: 1rem
            font-family: KelsonBold
            text-align: center
            font-size: 1.6rem
            padding: 1rem 0.5rem
            background: $darkGray2
            color: white
            border-bottom: 1px solid rgba(156,156,156,.4)
        .no-events
            text-align: center
        .image
            margin-top: 1rem
            img
                width: 100%
                max-height: 142px
        .name
            font-family: KelsonBold
            text-align: center
            margin-top: 0.5rem
            margin-bottom: 0.5rem
            font-size: 1.05rem
        .time, .location
            padding: 0.2rem 1rem
            img
                margin-right: 1rem
                width: 1.25rem
                height: 1.25rem
        .event .text-center
            margin-top: 0.5rem
            margin-bottom: 0.5rem
        .dark-blue-button
            margin-top: 0.5rem
        .office-hours
            padding-bottom: 1rem
            &.hide
                display: none !important
            .no-oh
                text-align: center
            h5
                margin-top: 1rem
            .time
                text-align: center
                &.changed
                    color: $niceRed
                &.cancelled
                    color: $niceRed
                    font-family: 'KelsonNormal'
                span:first-child
                    margin-right: 0.5rem
    // &::-webkit-scrollbar 
    //     width: 10px
    // /* Track */
    // &::-webkit-scrollbar-track 
    //     background: red
    // /* Handle */
    // &::-webkit-scrollbar-thumb 
    //     background: #888
    // /* Handle on hover */
    // &::-webkit-scrollbar-thumb:hover 
    //     background: #555
    
    h5
        text-align: center
        font-weight: bold
        font-family: KelsonBold
        // margin-bottom: 1rem
        // margin-top: 1rem

@media (max-width: 1317px)
    .sidebar
        display: none