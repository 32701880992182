@import '../variables.sass'
.header
    background: #f0f0f0 !important
    border-bottom: 1px solid #c3c3c3
    box-shadow: 0px -23px 39px 3px black
    height: 65px
    .navbar-brand
        display: flex
        align-items: center
        >a
            display: flex
            align-items: center
        .brand-logo
            height: 40px
            margin-top: -5px
            margin-bottom: -5px
        .brand-name
            font-family: KelsonBold
            margin-left: 1rem
            font-size: 1.5rem
    a
        text-decoration: none
        &:hover
            text-decoration: none
    .navbar-nav 
        a, .nav-link
            color: #a2a2a2
            font-family: KelsonBold
            display: flex
            align-items: center
            justify-content: center
            min-width: 80px
            padding: 0 1rem
            &.active
                color: $esnBlue
            &:hover
                color: $esnBlue
            &:focus
                color: $esnBlue
        .show>.nav-link
            color: $esnBlue
        .dropdown-menu
            .dropdown-item
                font-family: KelsonNormal
                padding: 0
                a
                    display: block
                    color: #a2a2a2
                    padding: .25rem 1.5rem
                    width: 100%
                    &.active
                        color: $esnBlue
                    &:hover
                        color: $esnBlue
                &:active
                    background: #d5d5d5
    &.dark-version
        background: #2f2f2f !important
        border-bottom: 0
        .navbar-nav a, .navbar-nav .nav-link
            color: white
        .navbar-nav a:hover, .navbar-nav .nav-link:hover
            color: $esnBlue
        .navbar-brand .brand-name
            color: white
        .navbar-nav a.active, .navbar-nav .nav-link.active
            color: $esnBlue
    // BOTTOM HEADER VERSION     
    &.mobile
        padding: 0
        border-top: 1px solid #c3c3c3
        box-shadow: 0px 10px 30px 5px black
        height: 60px
        .navbar-nav
            flex-direction: row
            a, .nav-link
                padding: .5rem 0
                width: 20%
                background: #f0f0f0
                // font-family: KelsonNormal
                flex-direction: column
                font-size: 0.8rem
                min-width: 0
                .active-img
                    display: none
                &.star
                    img
                        height: 40px
                        width: 40px
                &.active
                    background: #ffffffde
                    color: $esnBlue
                    .active-img
                        display: block
                    .non-active
                        display: none
                &.star.active
                    background: #f0f0f0
            .mobile-dropdown
                .active-img
                    display: none
                &.active
                    background: #ffffffde
                    color: $esnBlue
                    .active-img
                        display: block
                    .non-active
                        display: none
            .dropdown
                position: relative
                .submenu                    
                    position: absolute
                    display: none
                    bottom: 60px
                    right: 0
                    background: #f0f0f0
                    width: 500%
                    border-bottom: 1px solid #6d6d6d
                    border-top: 1px solid #6d6d6d
                    &.active
                        display: flex
                        flex-direction: row
                    a
                        width: 100%
                        .non-active
                            display: block
                        .active-img
                            display: none
                        &.active
                            .active-img
                                display: block
                            .non-active
                                display: none
                    .dropdown-item
                        background: #f0f0f0
                        padding: 0
            span
                margin-top: 3px
                font-size: 90%
            img
                height: 23px
                width: 23px
.header-mobile
    display: none
@media (max-width: 767px)
    .header.mobile.dark
        border-bottom: 0
        border-top: 0
        .navbar-nav
            .dropdown .submenu
                border-color: #383838
                height: 60px
            .nav-link, a, a.star.active
                background: $darkGray2
                height: 60px
    .construction-container
        padding-top: 80px
        overflow: hidden
    .header-mobile
        position: fixed
        top: 0
        display: block
        width: 100%
        height: 80px
        background: $esnDarkBlue
        z-index: 500
        box-shadow: 0px 0px 10px 1px black
        overflow: hidden
        display: flex
        justify-content: space-between
        align-items: center
        &.dark
            background: $darkGray2
    .logo
        position: relative
        left: 15px
        img
            height: 60px
    .sm
        display: flex
        align-items: center
        justify-content: center
        padding-left: 0.5rem
        padding-right: 0.5rem
        a
            padding-left: 0.25rem
            padding-right: 0.25rem
        img
            width: 30px
            border-radius: 5px