/* Create/Edit Event Form */
@import '../variables.sass'

.event-form
    .dormitory-container
        display: flex
        justify-content: space-between
        background: none
        border: none
    .label-container
        align-self: center
        font-family: KelsonBold
        label
            color: $esnDarkBlue
            margin-bottom: 0
    .dorm-select
        flex-basis: 1
    .preview-label
        color: $esnDarkBlue
        font-family: KelsonBold
        display: flex
        justify-content: center
        margin-bottom: 1rem
    .task-label
        margin-top: 1rem
    .image-wrapper
        flex-direction: column
    .image-label
        margin-bottom: 1rem
    .tasks-container
        width: 100%
        .single-task
            display: flex
            justify-content: space-between
            margin: 0.5rem 0
            .name
                width: 85%
            .icon-container
                display: flex
                align-items: center
            .icon-cross
                width: 1rem
                height: 1rem
        .add-task
            margin-top: 1rem
            display: flex
            justify-content: space-between

            input
                width: 80%
            .btn
                width: auto
                background: $esnDarkBlue
                border: 0
                &:active
                    background: $esnDarkBlue !important
                    box-shadow: 0 0 0 0.2rem rgba(38,143,255,.5)
                &:focus
                    box-shadow: 0 0 0 0.2rem rgba(38,143,255,.5)
    .react-datepicker-wrapper
        width: 100%
    .form-row
        margin-bottom: 20px
    .tox-tinymce
        width: 100%
    .esn-only-container
        margin-top: 1rem
        font-weight: bold
        label
            margin-right: 1rem
    .disclaimer
        color: #90949c
        font-size: 0.7rem
        line-height: 0.8rem
    .event-submit
        background: $esnDarkBlue
        border: 0
        min-width: 10rem
        &:active
            background: $esnDarkBlue !important
            box-shadow: 0 0 0 0.2rem rgba(38,143,255,.5)
        &:focus
            box-shadow: 0 0 0 0.2rem rgba(38,143,255,.5)
    .errors
        color: $niceRed
        text-align: center
        font-family: KelsonBold
        width: 100%
        margin-bottom: 1rem
@media screen and ( max-width:480px)
    .event-form .tasks-container .add-task input
        width: 70%