@import '../variables.sass'

.page-not-found
    display: flex
    flex-direction: column
    align-items: center
    img
        margin: 3rem auto
    .link
        margin-top: 1rem
        font-size: 2rem
        color: $esnBlue